@import ../../styles/helpers

.user
    position: relative
    width: 40px
    height: 40px
    +m
        position: static
    &.active
        .body
            visibility: visible
            opacity: 1
            transform: translateY(0)

.headUser
    display: flex,
    width: 100%
    height: 100%
    align-items: center,
    gap: 11px,
    border: none
    background-color: transparent

.head
    width: 100%
    height: 100%
    border-radius: 50%
    -webkit-tap-highlight-color: rgba(0,0,0,0),

    img
        width: 100%
        height: 100%
        object-fit: cover
        border-radius: 50%

.body
    position: absolute
    top: calc(100% + 18px)
    right: 10px
    width: 280px
    padding: 16px
    border-radius: 16px
    border: 1px solid transparent
    box-shadow: 0px 40px 64px -12px rgba(0, 0, 0, 0.08), 0px 0px 14px -4px rgba(0, 0, 0, 0.05), 0px 32px 48px -8px rgba(0, 0, 0, 0.1)
    background: $n
    visibility: hidden
    opacity: 0
    transform: translateY(3px)
    transition: all .2s
    +x
        top: calc(100% + 15px)
        right: 0
        border-radius: 12px
    +m
        top: 100%
        right: 16px
        left: 16px
        width: auto
        padding: 12px 16px 20px
    +dark
        background: $n8
        border-color: $n6
    &:before
        content: ""
        position: absolute
        right: 33px
        bottom: 100%
        width: 20px
        height: 10px
        background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='10' fill='none' viewBox='0 0 20 10'%3E%3Cpath d='M6.927 1.687L0 10h20l-6.927-8.313a4 4 0 0 0-6.146 0z' fill='%23fff'/%3E%3C/svg%3E") no-repeat 50% 50% / 100% auto
        +x
            right: 13px
        +m
            right: 21px
        +dark
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='10' fill='none' viewBox='0 0 20 10'%3E%3Cpath d='M6.927 1.687L0 10h20l-6.927-8.313a4 4 0 0 0-6.146 0z' fill='%23111315'/%3E%3C/svg%3E")

.menu
    display: flex
    flex-direction: column
    gap: 12px
    &:not(:last-child)
        margin-bottom: 12px
        padding-bottom: 12px
        border-bottom: 1px solid $n3
        +m
            margin-bottom: 8px
            padding-bottom: 8px
        +dark
            border-color: $n6

.item
    display: flex
    align-items: center
    width: 100%
    height: 48px
    padding: 0 12px
    border-radius: 12px
    +base1-s
    color: $n4
    transition: all .2s
    svg
        margin-right: 12px
        fill: $n4
        transition: fill .2s
    &:hover,
    &.active
        color: $n7
        svg
            fill: $n7
        +dark
            color: $n1
            svg
                fill: $n1
    &.active
        background: $n2
        +dark
            background: transparent
    &.color
        color: $p4
        svg
            fill: $p4
        +dark
            color: $p4
            svg
                fill: $p4
        &:hover
            opacity: .8
